import React from "react";
import Layout from "../layout";
import Accordion from "../sections/Accordion";
import Contact from "../sections/Contact";
import ExtendedFeatures from "../sections/ExtendedFeatures";
import GeneralFetures from "../sections/GeneralFetures";
import PricingPlans from "../sections/PricingPlans";
import SiteBanner from "../sections/SiteBanner";
import Trusties from "../sections/Trusties";

const Home = () => {
  return (
    <Layout>
      <SiteBanner />
      {/* <About /> */}
      <GeneralFetures />
      <ExtendedFeatures />
      <PricingPlans />
      <Trusties />
      {/* <Testimonials /> */}
      {/* <Blogs /> */}
      <Accordion />
      {/* <Download /> */}
      <Contact />
    </Layout>
  );
};

export default Home;
