import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFingerprint, FaPlus, FaTimes } from "react-icons/fa";

const extFeatureImage01 = require("../assets/images/1320-1198.jpg");
// const extFeatureImage02 = require("../assets/images/1320-1198 1.jpg");
// const extFeatureImage03 = require("../assets/images/1320-1198 2.jpg");

const ReadMore = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p>
      {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      <span
        onClick={toggleReadMore}
        style={{ color: "#fecc00", cursor: "pointer", marginLeft: "5px" }}
      >
        {isExpanded ? (
          <FaTimes title="Read Less" />
        ) : (
          <FaPlus title="Read More" />
        )}
      </span>
    </p>
  );
};

const ExtendedFeatures = () => {
  const { t } = useTranslation();
  const extFeturesTop = [
    {
      id: 1,
      icon: "las la-user",
      title: t("hr"),
      desc: t("hrDesc"),
    },
    {
      id: 2,
      icon: "las la-tasks",
      title: t("task"),
      desc: t("taskDesc"),
    },
    {
      id: 3,
      icon: "las la-warehouse",
      title: t("warehouse"),
      desc: t("warehouseDesc"),
    },
  ];

  const extFeturesTop2 = [
    {
      id: 1,
      icon: "las la-chart-bar",
      title: t("finance"),
      desc: t("financeDesc"),
    },
    {
      id: 2,
      icon: "las la-chalkboard-teacher",
      title: t("lms"),
      desc: t("lmsDesc"),
    },
    {
      id: 3,
      icon: "las la-address-book",
      title: t("crm"),
      desc: t("crmDesc"),
    },
  ];

  return (
    <section className="sp-t">
      <Container>
        <Row>
          <Col lg={8} className="mx-auto text-center mb-5">
            {/* - Section Heading - */}
            <div className="section-heading">
              <div className="heading-title">
                <h1>{t("headingModules")}</h1>
              </div>
            </div>
            {/* - Section Heading - */}
          </Col>
        </Row>

        {/* Three Columns with Three Rows Each */}
        <Row>
          {/* - First Column - */}
          <Col lg={4}>
            <div className="extended-feature-content mb-lg-0 mb-5">
              {(extFeturesTop || []).map((feature) => (
                <div key={feature.id} className="iconbox-secondary mb-4">
                  <div className="item-icon me-4">
                    <i className={feature.icon}></i>
                  </div>
                  <div className="item-content">
                    <h4>{feature.title}</h4>
                    <ReadMore text={feature.desc} maxLength={100} />
                  </div>
                </div>
              ))}
            </div>
          </Col>

          {/* - Second Column - */}
          <Col lg={4}>
            <div className="extended-feature-content mb-lg-0 mb-5">
              {(extFeturesTop2 || []).map((feature) => (
                <div key={feature.id} className="iconbox-secondary mb-4">
                  <div className="item-icon me-4">
                    <i className={feature.icon}></i>
                  </div>
                  <div className="item-content">
                    <h4>{feature.title}</h4>
                    <ReadMore text={feature.desc} maxLength={100} />
                  </div>
                </div>
              ))}
            </div>
          </Col>

          {/* - Third Column - */}
          <Col lg={4}>
            <div className="extended-feature-content mb-lg-0 mb-5">
              <div className="feature-mobile-image">
                <img
                  src={extFeatureImage01}
                  alt="Large Feature"
                  style={{ width: "100%", borderRadius: "15px" }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-5">
            <a href="#contact">
              <button
                className="btn btn-lg glossy-button"
                style={{
                  backgroundColor: "#fecc00",
                  color: "#000000",
                  border: "3px solid #000000",
                }}
              >
                <span
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginInline: "10px",
                  }}
                >
                  {t("moduleSignInButton")}
                </span>
                <span style={{ marginLeft: "0px", fontSize: "25px" }}>
                  <FaFingerprint />
                </span>
              </button>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ExtendedFeatures;
