import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./assets/locales/en.json";
import ruTranslation from "./assets/locales/ru.json";
import uzTranslation from "./assets/locales/uz.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    uz: { translation: uzTranslation },
    ru: { translation: ruTranslation },
  },
  lng: "uz", // default language
  fallbackLng: "uz",
  interpolation: { escapeValue: false },
});

export default i18n;
