import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Accordion = () => {
  const { t } = useTranslation();
  const accordionItems = [
    {
      id: 1,
      title: t("accordion.title1"),
      content: t("accordion.content1"),
    },
    {
      id: 2,
      title: t("accordion.title2"),
      content: t("accordion.content2"),
    },
    {
      id: 3,
      title: t("accordion.title3"),
      content: t("accordion.content3"),
    },
    {
      id: 4,
      title: t("accordion.title4"),
      content: t("accordion.content4"),
    },
    {
      id: 5,
      title: t("accordion.title5"),
      content: t("accordion.content5"),
    },
    {
      id: 6,
      title: t("accordion.title6"),
      content: t("accordion.content6"),
    },
  ];
  return (
    <section className="accordion sp-t" id="accordion">
      <Container>
        <Row>
          <Col lg={8} className="mx-auto text-center ">
            {/* - Section Heading - */}
            <div className="section-heading">
              <div className="heading-title">
                <h1>{t("faq")}</h1>
              </div>
            </div>
            {/* - Section Heading - */}
          </Col>
        </Row>
        <MDBContainer className="mt-5 mb-5" style={{ maxWidth: "1200px" }}>
          <MDBAccordion alwaysOpen>
            {accordionItems.map((item) => (
              <MDBAccordionItem
                key={item.id}
                collapseId={item.id}
                headerTitle={
                  <span className="accordion-header">{item.title}</span>
                }
              >
                <div class="accordion-content">{item.content}</div>
              </MDBAccordionItem>
            ))}
          </MDBAccordion>
        </MDBContainer>
      </Container>
    </section>
  );
};

export default Accordion;
