import i18n from "i18next"; // Import i18n to change the language
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next"; // Use the translation hook
import { FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";

const logoImage = require("../assets/images/Logo-Black-white.png");

const Header = () => {
  const { t } = useTranslation();
  const [scrolling, setScrolling] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const handleNavigation = () => {
    setOpenNav(!openNav);
  };

  // Function to change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", (e) => {
      e.preventDefault();
      handleScroll();
    });

    window.addEventListener("scroll", handleScroll);
  }, []);
  const navItems = [
    { title: t("home"), path: "#home" },
    // { title: t("about"), path: "#about" },
    { title: t("features"), path: "#features" },
    { title: t("pricing"), path: "#pricing" },
    // { title: "Testimonials", path: "#testimonials" },
    // { title: t("blogs"), path: "#blogs" },
    { title: t("contacts"), path: "#contact" },
  ];
  return (
    <>
      {/* - Site Header - */}
      <header
        className={`site-header d-lg-block d-none ${
          scrolling ? "sticky-header" : ""
        }`}
      >
        <Container>
          <Row>
            <Col md={12}>
              <div className="header-content">
                {/* - Header Left - */}
                <div className="header-left">
                  <div className="site-logo">
                    <a href="#home">
                      <img src={logoImage} alt="Site Logo" />
                    </a>
                  </div>
                </div>
                {/* - Header Left - */}

                {/* - Header Right - */}
                <div className="header-right">
                  <div className="site-navigation">
                    <ul>
                      {(navItems || []).map((item) => (
                        <li key={item.path}>
                          <a href={item.path}>{item.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Add language switch buttons */}
                  <div className="ms-4">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Language switcher"
                    >
                      <button
                        className="btn"
                        onClick={() => changeLanguage("en")}
                      >
                        <Flag country="US" /> {/* Flag for English */}
                      </button>
                      <button
                        className="btn ms-2"
                        onClick={() => changeLanguage("uz")}
                      >
                        <Flag country="UZ" /> {/* Flag for Uzbek */}
                      </button>
                      <button
                        className="btn ms-2"
                        onClick={() => changeLanguage("ru")}
                      >
                        <Flag country="RU" /> {/* Flag for Russian */}
                      </button>
                    </div>
                  </div>

                  <div className="ms-4">
                    <Link
                      to="tel:+998333093000"
                      className="anchor-button secondary-header button-sm"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <FaPhone
                        style={{ marginRight: "8px", transform: "scaleX(-1)" }}
                      />
                      +998 33 309 30 00
                    </Link>
                  </div>
                </div>
                {/* - Header Right - */}
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {/* - Site Header - */}

      {/* - Site Mobile Header - */}
      <header
        className={`site-mobile-header d-block d-lg-none ${
          scrolling ? "sticky-header" : ""
        }`}
      >
        <Container>
          <Row>
            <Col md={12}>
              <div className="header-content">
                {/* - Header Left - */}
                <div className="header-left">
                  <div className="site-logo">
                    <a href="#home">
                      <img
                        src={logoImage}
                        alt="Site Logo"
                        onClick={handleNavigation}
                      />
                    </a>
                  </div>
                </div>
                {/* - Header Left - */}

                {/* - Header Right - */}
                <div className="header-right">
                  <div className="me-4">
                    <Link
                      to="tel:+998333093000"
                      className="anchor-button secondary-header button-sm"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <FaPhone
                        style={{ marginRight: "8px", transform: "scaleX(-1)" }}
                      />
                      {/* Phone Icon */}
                      +998 33 309 30 00
                    </Link>
                  </div>
                  <div className="mobile-nav-toggle" onClick={handleNavigation}>
                    <i className="las la-bars"></i>
                  </div>
                </div>
                {/* - Header Right - */}
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {/* - Site Mobile Header - */}

      {/* - Mobile Navigation - */}
      <div className={`mobile-navigation-wrap p-4 ${openNav ? "show" : ""}`}>
        <div className="nav-header">
          <i
            className="las la-times-circle hide-mobile-nav"
            onClick={handleNavigation}
          ></i>
        </div>

        {/* - Navigation Items - */}
        <div className="mobile-nav">
          <ul>
            {(navItems || []).map((item) => (
              <li key={item.path}>
                <a href={item.path} onClick={handleNavigation}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <ul>
            <div className="ms-4">
              <div
                className="btn-group"
                role="group"
                aria-label="Language switcher"
              >
                <button className="btn" onClick={() => changeLanguage("en")}>
                  <Flag country="US" /> {/* Flag for English */}
                </button>
                <button
                  className="btn ms-2"
                  onClick={() => changeLanguage("uz")}
                >
                  <Flag country="UZ" /> {/* Flag for Uzbek */}
                </button>
                <button
                  className="btn ms-2"
                  onClick={() => changeLanguage("ru")}
                >
                  <Flag country="RU" /> {/* Flag for Russian */}
                </button>
              </div>
            </div>
          </ul>
        </div>
        {/* - Navigation Items - */}
      </div>
      {/* - Mobile Navigation - */}
    </>
  );
};

export default Header;
