import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Use the translation hook
import { FaFingerprint } from "react-icons/fa";

const bannerImage = require("../assets/images/site-banner-1.png");
const SiteBanner = () => {
  const { t } = useTranslation();
  return (
    <section className="site-banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            {/* - Banner Heading - */}
            <div className="banner-heading">
              <div className="banner-text mb-4">
                <div className="banner-title">
                  <h1>
                    {t("bannerTitle")}
                    <br />
                    {t("bannerTitle2")}
                  </h1>
                </div>
                <div className="banner-description">
                  <span style={{ fontSize: "18px" }}>
                    <p>{t("bannerDescription")}</p>
                  </span>
                </div>
              </div>

              <a href="#contact">
                <button
                  className="btn btn-lg glossy-button"
                  style={{
                    backgroundColor: "#fecc00",
                    color: "#000000",
                    border: "3px solid #000000",
                  }}
                >
                  <span
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      marginInline: "10px",
                    }}
                  >
                    {t("buttonDesc")}
                  </span>
                  <span style={{ marginLeft: "0px", fontSize: "25px" }}>
                    <FaFingerprint />
                  </span>
                </button>
              </a>
            </div>
            {/* - Banner Heading - */}
          </Col>

          <Col lg={6} className="mt-lg-0 mt-5 d-lg-block d-none ">
            {/* - Banner Image - */}
            <div className="banner-image custom-margin-left">
              <img
                src={bannerImage}
                alt="Mobile Banner"
                className="rounded-image"
              />
            </div>
            {/* - Banner Image - */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SiteBanner;
