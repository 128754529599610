import emailjs from "emailjs-com";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  const [contactFormData, setContactFormData] = useState({
    firstName: "",
    lastname: "",
    number: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = (e) => {
    setContactFormData((preValues) => {
      return {
        ...preValues,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true on form submission

    // Send email using EmailJS
    emailjs
      .send(
        "service_9qhhuv2",
        "template_2uen2m7",
        {
          firstName: contactFormData.firstName,
          lastname: contactFormData.lastname,
          number: contactFormData.number,
          message: contactFormData.message,
        },
        "hJTsBU0WLfqxRr3WV"
      )
      .then((result) => {
        console.log("Email successfully sent:", result.text);
        // Clear form fields after successful email sending
        setContactFormData({
          firstName: "",
          lastname: "",
          number: "",
          message: "",
        });
        setLoading(false); // Reset loading to false
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setLoading(false); // Reset loading to false even on error
      });
  };

  // Check if any field is empty
  const isFormValid =
    contactFormData.firstName &&
    contactFormData.lastname &&
    contactFormData.number &&
    contactFormData.message;

  return (
    <>
      <div className="contact-form">
        <div className="mb-4 mt-4">
          <div className="mb-2">
            <h3>{t("contactHeader")}</h3>
          </div>
          <p></p>
        </div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-4">
              <input
                type="text"
                placeholder={t("name")}
                name="firstName"
                onChange={handleChange}
                value={contactFormData.firstName}
              />
            </Col>
            <Col md={6} className="mb-4">
              <input
                type="text"
                placeholder={t("lastname")}
                name="lastname"
                onChange={handleChange}
                value={contactFormData.lastname}
              />
            </Col>
            <Col md={12} className="mb-4">
              <input
                type="text"
                placeholder={t("phone")}
                name="number"
                onChange={handleChange}
                value={contactFormData.number}
              />
            </Col>
          </Row>

          <Col md={12} className="mb-4">
            <textarea
              placeholder={t("message")}
              name="message"
              onChange={handleChange}
              value={contactFormData.message}
              className="w-100"
            />
          </Col>

          <Col md={12} className="mb-4">
            <button
              type="submit"
              className="anchor-button primary button-lg w-100"
              disabled={loading || !isFormValid} // Disable button if loading or form is invalid
            >
              {loading ? "Sending..." : t("send")}{" "}
              {/* Show loader text while sending */}
            </button>
            {/* {loading && <div className="loader">Loading...</div>}{" "}
            Add your loader here */}
          </Col>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
