import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContactForm from "../components/CotactForm";

const contactInfoData = [
  {
    id: 1,
    icon: "las la-envelope",
    title: "Email Us",
    desc: "info@emplbee.com",
  },
  {
    id: 2,
    icon: "las la-phone-volume",
    title: "Call Us",
    desc: "+998 33 309-30-00",
  },
  {
    id: 3,
    icon: "las la-map",
    title: "Visit Us",
    desc: "Uzbekistan, 100207, Tashkent, Yashnabad, 2 proezd, ul. Chulpon, 14A",
  },
];

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="sp-tb" id="contact">
      <Container>
        <Row>
          <Col lg={6}>
            {/* - Section Heading - */}
            <div className="section-heading mb-5">
              <div className="heading-title">
                <h1>{t("contact")}</h1>
              </div>
            </div>
            {/* - Section Heading - */}

            {/* - Contact Info - */}
            <div className="contact-infos mt-5">
              {(contactInfoData || []).map((info) => (
                <div className="iconbox-secondary mb-4" key={info.id}>
                  <div className="item-icon me-4">
                    <i className={info.icon}></i>
                  </div>
                  <div className="item-content">
                    <h4>{info.title}</h4>
                    <p>{info.desc}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* - Contact Info - */}
          </Col>
          <Col lg={6}>
            {/* - Contact Form - */}
            <ContactForm />
            {/* - Contact Form - */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
