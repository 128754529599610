import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFingerprint } from "react-icons/fa";

const genFeatureMainImage = require("../assets/images/1185-1476.jpg");

const GeneralFetures = () => {
  const { t } = useTranslation();
  const featuresLeft = [
    {
      id: 1,
      icon: "las la-store",
      title: t("retail"),
      desc: t("retailDesc"),
    },

    {
      id: 2,
      icon: "las la-industry",
      title: t("production"),
      desc: t("productionDesc"),
    },
    {
      id: 3,
      icon: "las la-school",
      title: t("educationalInstitutions"),
      desc: t("educationalInstitutionsDesc"),
    },
  ];

  const featuresRight = [
    {
      id: 1,
      icon: "las la-shopping-cart",
      title: t("onlineShopping"),
      desc: t("onlineShoppingDesc"),
    },
    {
      id: 2,
      icon: "las la-hand-holding-usd",
      title: t("installmentPayment"),
      desc: t("installmentPaymentDesc"),
    },
    {
      id: 3,
      icon: "las la-network-wired",
      title: t("distribution"),
      desc: t("distributionDesc"),
    },
  ];

  return (
    <section className="sp-t" id="features">
      <Container>
        <Row>
          <Col lg={8} className="mx-auto text-center mb-5">
            {/* - Section Heading - */}
            <div className="section-heading">
              <div className="heading-title">
                <h1>{t("headingTitleGeneralFeatures")}</h1>
              </div>
            </div>
            {/* - Section Heading - */}
          </Col>
        </Row>

        <Row className="align-items-center">
          {/* - Features Data Display - */}
          <Col lg={4}>
            {(featuresLeft || []).map((feature) => (
              <div key={feature.id} className="iconbox-secondary mb-4">
                <div className="item-icon me-4">
                  <i className={feature.icon}></i>
                </div>
                <div className="item-content">
                  <h4>{feature.title}</h4>
                  <p>{feature.desc}</p>
                </div>
              </div>
            ))}
          </Col>
          {/* - Features Data Display - */}

          {/* - Features Image - */}
          <Col lg={4} className="my-lg-0 my-5">
            <div className="feature-mobile-image">
              <img
                src={genFeatureMainImage}
                alt="Feature 01"
                className="rounded-image"
              />
            </div>
          </Col>
          {/* - Features Image - */}

          {/* - Features Data Display - */}
          <Col lg={4}>
            {(featuresRight || []).map((feature) => (
              <div key={feature.id} className="iconbox-secondary mb-4">
                <div className="item-icon me-4">
                  <i className={feature.icon}></i>
                </div>
                <div className="item-content">
                  <h4>{feature.title}</h4>
                  <p>{feature.desc}</p>
                </div>
              </div>
            ))}
          </Col>
          {/* - Features Data Display - */}
        </Row>

        <Row>
          <Col className="text-center mt-5">
            <a href="#contact">
              <button
                className="btn btn-lg glossy-button"
                style={{
                  backgroundColor: "#fecc00",
                  color: "#000000",
                  border: "3px solid #000000",
                }}
              >
                <span
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    marginInline: "10px",
                  }}
                >
                  {t("buttonDesc")}
                </span>
                <span style={{ marginLeft: "0px", fontSize: "25px" }}>
                  <FaFingerprint />
                </span>
              </button>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GeneralFetures;
