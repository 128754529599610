import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PricingPlans = () => {
  const { t } = useTranslation();
  const pricingPlans = [
    {
      id: 1,
      type: "basic",
      title: t("plus"),
      desc: t("plusDesc"),
      price: "199,999",
      link: "#",
    },
    {
      id: 2,
      type: "professional",
      title: t("premium"),
      desc: t("premiumDesc"),
      price: "999,999",
      link: "#",
    },
  ];
  return (
    <section className="pricing-plan sp-tb" id="pricing">
      <Container>
        <Row>
          <Col lg={8} className="mx-auto text-center mb-5">
            {/* - Section Heading - */}
            <div className="section-heading">
              <div className="heading-title">
                <h1>{t("priceHeader")}</h1>
              </div>
            </div>
            {/* - Section Heading - */}
          </Col>
        </Row>
        {/* - Pricing Boxes - */}
        <Row>
          {(pricingPlans || []).map((plan) => (
            <Col key={plan.id} lg={6} className="mb-lg-0 mb-4">
              <div
                className={`${
                  plan.type === "professional"
                    ? "pricing-plan-single p-5 plan-professional"
                    : "pricing-plan-single p-5"
                }`}
              >
                <div className="plan-heading mb-5">
                  <h3>{plan.title}</h3>
                  <p>{plan.desc}</p>
                </div>
                <div className="plan-cost mb-5">
                  <span className="price">UZS {plan.price}</span>
                  <span className="schedule">/Module</span>
                </div>
                <div className="plan-action">
                  <div className="mb-4">
                    <Link
                      to="#"
                      className={
                        plan.type === "professional"
                          ? "anchor-button secondary button-lg"
                          : "anchor-button primary button-lg"
                      }
                    >
                      {t("pricesButtons")}
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/* - Pricing Boxes - */}
      </Container>
    </section>
  );
};

export default PricingPlans;
