import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const trustiesList = [
  { id: 1, title: "Kasbino", image: require("../assets/images/kasbino.png") },
  { id: 2, title: "Byork", image: require("../assets/images/byork.png") },
  { id: 3, title: "Zfirst", image: require("../assets/images/zfirst.png") },
  { id: 4, title: "MAAB", image: require("../assets/images/maab2.png") },
  { id: 5, title: "LOOOK", image: require("../assets/images/loook.jpg") },
];

const Trusties = () => {
  const { t } = useTranslation();
  return (
    <section>
      <Container>
        <Row>
          <Col lg={4}>
            {/* - Section Heading - */}
            <div className="section-heading mb-5">
              <div className="heading-title">
                <h1>{t("trustiesTitle")}</h1>
              </div>
              <div className="heading-description">
                <p>
                  Would You Give Someone Your Last Trusted by? Get the Door -
                  It's Trusted by.
                </p>
              </div>
            </div>
            {/* - Section Heading - */}
          </Col>

          <Col lg={8}>
            {/* Carousel Integration */}
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop
              autoPlay
              interval={1500}
              showArrows={true}
              centerMode={true}
              centerSlidePercentage={35}
              renderIndicator={() => null}
            >
              {trustiesList.map((trustie, index) => (
                <div key={trustie.id} className="carousel-slide">
                  <img src={trustie.image} alt={trustie.title} />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Trusties;
